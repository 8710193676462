#header-login-panel {
  background-color: lightgray;
  position: absolute;
  height: 50px;
  width: fit-content;
  top: 0;
  right: 10px;
  margin: 0;
  padding: 0;
  border-radius: 0 0 10px 10px;
}

#header-text {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}