#footer-grid-panel {
  background-color: lightgray;
  position: absolute;
  height: 40px;
  width: fit-content;
  bottom: 0;
  right: 10px;
  margin: 0;
  padding: 10px 10px 0 10px;
  border-radius: 10px 10px 0 0;
}

.zoom-img {
  height: 26px;
  width: 26px;
  margin: 4px;
  display: inline-block;
  cursor: pointer;
}