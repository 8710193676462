.GridItem {
  display: inline-block;
  border: 1px;
  border-style: solid;
  margin: 0;
  padding: 0;
}

.GridItem:hover {
  border-color: black !important;
  cursor: pointer;
}
